import React from "react";
import withLayout from "../components/withLayout";
import { graphql } from "gatsby";
import {
  PageContainer,
  PageHeader,
  HR,
  WhiteContentBox
} from "../components/common";

const Terms = ({ content }) => (
  <PageContainer>
    <PageHeader>
      <h1>{content.title}</h1>
      <h4>{content.subtitle}</h4>
    </PageHeader>
    <WhiteContentBox>
      <h2 style={{ marginTop: 0 }}>{content.summary_header}</h2>
      <HR />
      <div dangerouslySetInnerHTML={{ __html: content.summary }} />
      <h2 style={{ marginTop: 0 }}>{content.more_info_header}</h2>
      <HR />
      <div dangerouslySetInnerHTML={{ __html: content.more_info }} />
    </WhiteContentBox>
  </PageContainer>
);

export default withLayout(Terms);

export const query = graphql`
  query getTerms($Locale: String!) {
    content: allAirtable(
      filter: { table: { eq: "Terms" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...static
    }
    layout: allAirtable(
      filter: { table: { eq: "Layout" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...layout
    }
  }
`;
